<template>
  <div class="container">
    <!-- 表格显示 -->
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%">
      <el-table-column label="ISBN" align="center">
        <template v-slot="{ row }">
          <span>{{ row.StrISBN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交人" align="center">
        <template v-slot="{ row }">
          <span>{{ row.StrCreatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" align="center">
        <template v-slot="{ row }">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="390px"
      >
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" @click="BookErrorCorrection(row)">纠错</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :title="`图书纠错`"
        width="60%"
        v-model="dialogVisible"
        top="7vh">
      <!-- 第一个表单 -->
      <el-form label-width="100px" size="small"><!--:model="formCXTSModel"-->
        <el-row :gutter="20">
          <el-col>
            <el-form :inline="true" label-width="100px">
              <el-form-item label="ISBN" prop="title">
                {{ formCXTSModel.keywords }}
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-form>
      <!-- 第二个表单 -->
      <el-form
          ref="tsDataForm"
          label-width="100px"
          size="small"
          :rules="tsRules"
          :model="formLRTSModel">
        <el-row>
          <!--        //2023/8/23 17:40 原来的版本-->
          <el-col :span="8" style="border: #cccccc">
            <el-form-item label="封面:">
              <!--            <el-upload-->
              <!--                class="avatar-uploader"-->
              <!--                :action="uploadUrl"-->
              <!--                accept=".jpg,.jpeg,.png"-->
              <!--                :headers="picheaders"-->
              <!--                :data="uploadtype"-->
              <!--                :class="{ disabled: uploadDisabled }"-->
              <!--                list-type="picture-card"-->
              <!--                :before-upload="beforeUpload"-->
              <!--                :on-preview="handlePictureCardPreview"-->
              <!--                :on-change="handleChange"-->
              <!--                :on-remove="handleRemove"-->
              <!--                :on-success="handleSuccess"-->
              <!--                :file-list="fileList">-->
              <!--              <i class="el-icon-plus"/>-->
              <!--            </el-upload>-->
              <el-upload
                  class="avatar-uploader"
                  :class="{ disabled: uploadDisabled }"
                  :headers="picheaders"
                  :data="uploadtype"
                  :action="uploadUrl"
                  list-type="picture-card"
                  :before-upload="beforeUpload"
                  :on-preview="handlePictureCardPreview"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  accept=".jpg,.jpeg,.png"
                  :file-list="fileList">
                <i class="el-icon-plus"/>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="正题名" prop="Title">
              <el-input
                  v-model="formLRTSModel.Title"
                  placeholder="请填写正题名"
              />
            </el-form-item>
            <el-form-item label="第一责任者" prop="Author">
              <el-input
                  v-model="formLRTSModel.Author"
                  placeholder="请填写第一责任者"
              />
            </el-form-item>
            <el-form-item label="其他责任者">
              <el-input
                  v-model="formLRTSModel.OtherResponsible"
                  class="input-with-select"
                  placeholder="请填写其他责任者"
              />
            </el-form-item>
            <el-form-item label="分类号" prop="Class">
              <el-input
                  v-model="formLRTSModel.Class"
                  placeholder="请填写分类号"/>
            </el-form-item>
            <!--          <el-form-item label="并列题名">-->
            <!--            <el-input-->
            <!--                v-model="formLRTSModel.StrParallelTitle"-->
            <!--                placeholder="请填写并列题名"-->
            <!--            />???-->
            <!--          </el-form-item>-->
          </el-col>
          <el-col :span="8">
            <el-form-item label="装订">
              <el-input
                  v-model="formLRTSModel.Binding"
                  placeholder="请填写装订"
              />
            </el-form-item>
            <el-form-item label="卷页数">
              <el-input
                  v-model="formLRTSModel.Page"
                  placeholder="请填写卷页数"
              />
            </el-form-item>
            <el-form-item label="副题名">
              <el-input
                  v-model="formLRTSModel.Subtitle"
                  placeholder="请填写副题名"
              />
            </el-form-item>

            <el-form-item label="主题词">
              <el-input
                  v-model="formLRTSModel.Keyword"
                  placeholder="请填写主题词"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本">
              <el-input
                  v-model="formLRTSModel.Edition"
                  placeholder="请填写版本"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卷册名">
              <el-input
                  v-model="formLRTSModel.SectionNum"
                  placeholder="请填写卷册名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="丛书题名">
              <el-input
                  v-model="formLRTSModel.SeriesTitle"
                  placeholder="请填写丛书题名"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="卷册">
              <el-input
                  v-model="formLRTSModel.SectionName"
                  placeholder="请填写卷册"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版日期">
              <el-input
                  v-model="formLRTSModel.Pubdate"
                  placeholder="请填写出版日期"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版地">
              <el-input
                  v-model="formLRTSModel.Pubplace"
                  placeholder="请填写出版地"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出版社">
              <el-input
                  v-model="formLRTSModel.Publisher"
                  placeholder="请填写出版社"
              />
            </el-form-item>
            <el-form-item label="每册价格" prop="Price">
              <el-input
                  v-model="formLRTSModel.Price"
                  placeholder="请填写每册价格"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="附注">
              <el-input
                  v-model="formLRTSModel.Annotations"
                  placeholder="请填写附注"
              />
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="简介">
              <el-input
                  type="textarea"
                  rows="4"
                  v-model="formLRTSModel.Summary"
                  placeholder="请填写简介"
              />
            </el-form-item>
          </el-col>

          <el-col :span="22">
          </el-col>
          <span class="dialog-footer" style="float: right">
          <el-button size="small" type="primary" @click="sureErrorModify">确定</el-button>
        </span>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 分页 -->
    <div style="margin-top: 50px; text-align: right" >
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.PerPage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <!--    不要删除，这是为了防止el-upload元素找不到而设置的，el-upload实际使用的
      元素是点击编辑后的图片添加框，但是在第一次uploadDisabled使用时图片添加框不存在-->
    <div class="el-upload"></div>
  </div>
</template>

<script>
import md5 from "js-md5";
import Compressor from "compressorjs";
import global from "@/utils/global";

export default {
  name: "errorManager",
  data() {
    var validatePrice = (rule, value, callback) => {
        const regex = /^\d+(\.\d{1,2})?$/; // 正则表达式，匹配纯数字或包含一位小数的数字
      if (value === '') {
        callback(new Error('请输入价格'));
      } else if(!regex.test(value)){
        callback(new Error('请输入纯数字或包含一位小数的数字'));
      }else {
        callback();
      }
    };
    return {
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        Page: 1,
        PerPage: 10,
      },
      dialogVisible: false,
      formCXTSModel: {
        pageIndex: 1,
        pageSize: 10,
        keyName: "StrISBN",
        keywords: "",
      },
      formLRTSModel: {},
      uploadtype: {
        type: 'image/bookcover'
      },
      picheaders: {},
      uploadUrl: "",
      fileList: [],
      tsRules: {
        Title: [
          {required: true, message: "请输入正题名", trigger: "blur"},
        ],
        Author: [
          {required: true, message: "请输入第一责任者", trigger: "blur"},
        ],
        Class: [
          {required: true, message: "请输入分类号", trigger: "blur"},
        ],
        Price: [
          {validator: validatePrice, trigger: "blur",required: true},
        ],
        ISBN: [{required: true, message: "请输入ISBN", trigger: "blur"}],
      },
    }
  },
  computed: {
    uploadDisabled: function () {
      const uploadElement = document.querySelector('.el-upload');
      if (this.fileList.length > 0) {
        uploadElement.style.display = 'none';
        return true;
      } else {
        uploadElement.style.display = 'inline-block';
        return false;
      }
    },
  },
  created() {
    this.uploadUrl = global.syOssUrl + '/upload2Oss'
    this.getList()
  },
  methods: {
    getList() {
      this.$axios({
        url: this.localpath + "system/errorIsbnInfos",
        method: "post",
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.list = res.data.msg;
          this.total = res.data.total;
          this.listLoading = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.listLoading = false;
        }
      });
    },
    sureErrorModify() {
      // this.formLRTSModel.IntReservationRoyalty = parseInt(
      //     this.formLRTSModel.IntReservationRoyalty
      // );
      // this.formLRTSModel.IntMemoryCopyNum = parseInt(
      //     this.formLRTSModel.IntMemoryCopyNum
      // );
      // this.formLRTSModel.IntNumberCopies = parseInt(
      //     this.formLRTSModel.IntNumberCopies
      // );
      this.formLRTSModel.Price =
          this.formLRTSModel.Price.toString();
      this.$refs["tsDataForm"].validate((valid) => {
        if (valid) {
          this.$axios({
            url: this.localpath + "system/modifyIsbnInfo",
            method: "post",
            data: JSON.stringify(this.formLRTSModel),
            //发送格式为json
            headers: {
              "Content-Type": "application/json",
              dataType: "json",
              Authorization: localStorage.getItem("token"),
            },
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getList()
              this.dialogVisible = false
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      })
    },
    BookErrorCorrection(row) {
      this.formCXTSModel.keywords = row.StrISBN
      if (this.formCXTSModel.keywords.length === 0 || this.formCXTSModel.keywords.length !== 10 && this.formCXTSModel.keywords.length !== 13) {
        return this.$message.error(
            "ISBN号不正确"
        );
      }
      const param = {
        keyName: 'StrISBN',
        keywords: this.formCXTSModel.keywords,
      };
      this.$axios({
        url: this.localpath + "system/isbn/" + param.keywords,
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        if (res.data.ok) {
          this.formLRTSModel.Title = res.data.msg.Title;
          this.formLRTSModel.Annotations = res.data.msg.Annotations
          this.formLRTSModel.OtherResponsible = res.data.msg.OtherResponsible
          this.formLRTSModel.SectionNum = res.data.msg.SectionNum
          this.formLRTSModel.SectionName = res.data.msg.SectionName
          this.formLRTSModel.SeriesTitle = res.data.msg.SeriesTitle
          // this.formLRTSModel.StrLotNumber = this.StrLotNumber;
          // this.formLRTSModel.StrLotNumberID = this.StrLotNumberID;
          this.formLRTSModel.Subtitle = res.data.msg.Subtitle;
          this.formLRTSModel.Author = res.data.msg.Author;
          this.formLRTSModel.Binding = res.data.msg.Binding;
          this.formLRTSModel.Page = res.data.msg.Page.toString();
          this.formLRTSModel.ISBN = res.data.msg.ISBN;
          this.formLRTSModel.Pubdate = res.data.msg.Pubdate;
          this.formLRTSModel.Pubplace = res.data.msg.Pubplace;
          this.formLRTSModel.Publisher = res.data.msg.Publisher;
          this.formLRTSModel.Keyword = res.data.msg.Keyword;
          this.formLRTSModel.Class = res.data.msg.Class;
          this.formLRTSModel.Price = res.data.msg.Price;
          this.formLRTSModel.Edition = res.data.msg.Edition;
          this.formLRTSModel.Summary = res.data.msg.Summary;
          // this.formLRTSModel.IntReservationRoyalty = 1;
          // this.formLRTSModel.IntMemoryCopyNum = 1;
          // this.formLRTSModel.IntNumberCopies = 1;
          this.formLRTSModel.Cover = res.data.msg.Cover
          this.fileList = []
          this.toSetCover(this.formLRTSModel.Cover, this.formLRTSModel.ISBN)
          this.dialogVisible = true
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 2000,
          });
        }
      })
    },
    //设置图片URL
    toSetCover(coverUrl, ISBN) {
      if (coverUrl !== "") {
        this.fileList = []
        this.checkImage(coverUrl)
            .then((result) => {
              if (result) {
                // 图片路径可访问
                this.fileList.push({
                  url: coverUrl,
                });
              } else {
                // 图片路径无法访问，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              }
            })
            .catch(() => {
              // 图片路径检查出错，添加默认图片URL
              this.fileList.push({
                url: require('../../assets/img/defaultBook.png'),
              });
            });
      } else {
        const imgsrc = 'https://sanyue-oss-public.oss-cn-hangzhou.aliyuncs.com/image/bookcover/' + ISBN + '.jpg'
        this.checkImage(imgsrc)
            .then((result) => {
              if (result) {
                // 图片路径可访问
                this.fileList.push({
                  url: imgsrc,
                });
              } else {
                // 图片路径无法访问，添加默认图片URL
                this.fileList.push({
                  url: require('../../assets/img/defaultBook.png'),
                });
              }
            })
            .catch(() => {
              // 图片路径检查出错，添加默认图片URL
              this.fileList.push({
                url: require('../../assets/img/defaultBook.png'),
              });
            });
      }
    },
    checkImage(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = function () {
          resolve(true); // 图片加载成功
        };
        img.onerror = function () {
          resolve(false); // 图片加载失败
        };
        img.src = url;
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.now = Date.parse(new Date()) / 1000;
        this.picheaders = {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23' + this.now)
        },
            new Compressor(file, {
              quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
      });
    },
    handlePictureCardPreview(file) {
      //默认是下载而不是打开
      window.open(file.url)
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.formLRTSModel.Cover = ''
      setTimeout(() => {
        this.fileList = fileList
      }, 1000); // 1 秒延迟
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.fileList = fileList
        this.formLRTSModel.Cover = response.msg
      }
    },
    pageSizeChange(Perpage) {
      this.listQuery.PerPage = Perpage;
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page;
      this.getList()
    },
  }
}
</script>

<style scoped>
.el-upload {

}
</style>
